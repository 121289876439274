export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Programs",
    model: null,
    options: [
      {
        value: null,
        label: "All",
      },
      {
        value: 7,
        label: "BOOS CREDIT",
      },
      {
        value: 5,
        label: "DEBT SOLUTION",
      },
      {
        value: 21,
        label: " CREDIT EXPERTS",
      },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false,
  },

]
