<template>

  <b-modal
    hide-footer
    size="lg"
    @hidden="$emit('hidden')"
    ref="modal-tracking-reports"
    title="TRACKING REPORTS"
    body-class="p-0"
    scrollable
  >
    <div class="my-1">
      <b-row class="d-flex justify-content-center m-0">
        <b-col cols="12" md="6">
          <span class="title-tag">ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ account || "-" }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span class="title-tag">CLIENT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ client_name || "-" }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-container>
    <b-table
      ref="reportTable"
      :items="myProvider"
      :fields="fields"
      :filter="[]"
      show-empty
      empty-text="There are no records to show"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(created_at)="data">
        <span style="color: rgba(0, 204, 0, 1)">{{
          data.item.created_at | myGlobalDay
        }}</span>
      </template>
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="isVariantStatus(data.item.status)"
          style="padding: 8px; width: 90%"
        >
          <span>{{ data.item.status }}</span>
        </b-badge>

      </template>
    </b-table>
    </b-container>
  </b-modal>

</template>
<script>

import SendingReportsService from "@/views/correspondence/views/sending-reports/service/sending-reports.service";
export default {
  mounted() {
    this.toggleModal("modal-tracking-reports");
  },
  components: {
    SendingReportsService,
  },
  //  lead_id es el antiguo offerPaymentOrderId
  props: {
    lead_id: {
      type: Number,
    },
    account: {
      type: String,
    },
    client_name: {
      type: String,
    },
  },
  data() {
    return {
      fields: [
        {
          label: "date",
          key: "created_at",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   key: "client_name",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          key: "created_by",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "program",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "state",
          tdClass: "text-center",
          thClass: "text-center",
          key: "status",
        },
      ],
      isBusy: false,
    };
  },
  methods: {
    isVariantStatus(status) {

          if (status == "COMPLETED") {
            return "success";
          } else {
            return "warning";
          }
    },
    async myProvider() {
      try {
        const id = this.lead_id;
        const { data } =
          await SendingReportsService.getSendingReportsTracking(id);
          return data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
