<template>
  <div>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="reload"
      @onChangeCurrentPage="onChangeCurrentPage"
    >


      <template #table>
        <b-table
          slot="table"
          :ref="`refSendingToCreditors${typeTab}`"
          small
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <div>
              <span class="text-primary">{{ data.item.client_name }}</span>
              <br />
              <span>{{ data.item.account }}</span>
            </div>
          </template>

           <template #cell(created_by)="data">
            <div>
              <span>{{ data.item.created_by }}</span>
              <br />
              <span>{{ data.item.created_at || myGlobalDay }}</span>
            </div>
          </template>chr

          <template #cell(files)="data">
            <div class="d-flex justify-content-center" style="gap: 10px">
              <div class="text-center"></div>
              <div style="width: 20px">
                <a target="_blank" class="pr-2" :href="data.item.urlFile">
                  <b-img
                    :src="require('@/assets/images/icons/pdf.png')"
                    width="15"
                    height="20"
                  >
                  </b-img>
                </a>
              </div>
            </div>
          </template>

          <template #cell(tracking)="data">
            <b-button
              variant="outline"
              class="text-center widthClients text-light p-0"
              @click="ModalTracking(data.item)"
            >
              <feather-icon
                icon="ListIcon"
                size="15"
                :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                class="mr-50"
              />
            </b-button>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="CheckIcon"
                class="text-success cursor-pointer"
                size="20"
                @click="sentStatement(data.item.id)"
              ></feather-icon>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <ModalTrackingReports
      v-if="statusModalTrackingShipment"
      :lead_id="lead_id"
      :account="account"
      :client_name="client_name"
      @hidden="statusModalTrackingShipment = false"
    />
  </div>
</template>

<script>
// Store
import { mapGetters, mapActions } from "vuex";

// Data
import fields from "@/views/correspondence/views/sending-reports/view/components/data/fields.data";
import filters from "@/views/correspondence/views/sending-reports/view/components/data/filters.data";
// Services
import SendingReportsService from "@/views/correspondence/views/sending-reports/service/sending-reports.service";

// Modals

import ModalTrackingReports from "@/views/correspondence/views/sending-reports/view/components/modal/ModalTrackingReports.vue";

export default {
  components: {
    ModalTrackingReports
  },
  data() {
    return {
      statusModalTrackingShipment: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      totalRows: 0,
      startPage: null,
      endPage: null,
      toPage: null,
      isBusy: false,
      sortBy: "",
      sortDesc: true,
      fields,
      filters,
      items: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTable: "SendingReportStore/getRefreshTable",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    typeTab() {
      switch (this.$route.matched[2].name) {
        case this.$route.matched[2].meta.inProccessTab:
          return 0; // In Proccess
        case this.$route.matched[2].meta.sentTab:
          return 1; // Sent
      }
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },

    isAdvisor() {
      return this.currentUser.role_id == 3;
    },

    hasPermission() {
      return this.isCeo || this.isSupervisor;
    },
  },
  async created() {
     if ( this.moduleId == 9 ) {
        this.filters[2].visible = true;
    } 

    this.filterColumn("sent_date", this.typeTab == 1);
    this.filterColumn("actions", this.typeTab != 1 && this.moduleId == 9) ;
  },
  methods: {
     ...mapActions({
      CHANGE_REFRESH_TABLE: "SendingReportStore/CHANGE_REFRESH_TABLE",
    }),

    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    async sentStatement(id) {
      const swal = await this.showConfirmSwal();
      if (swal.isConfirmed) {
        try {
          const response =
            await SendingReportsService.updateStatusSendingReport({
              id,
              created_by: this.currentUser.user_id
            });
          if (response.status == 200) {
            this.showSuccessSwal("OPERATION SUCCESSFULLY");
            this.removePreloader();
            this.reload();
          }
        } catch (error) {
          console.error(error);
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },

    async reload() {
      this.$refs[`refSendingToCreditors${this.typeTab}`].refresh();
    },

    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    async myProvider(ctx) {
      try {
        const params = {
          search: this.filterPrincipal.model,
          perpage: ctx.perPage,
          page: ctx.currentPage,
          orderby: 9,
          order: "desc",
          from: this.filters[0].model,
          to: this.filters[1].model,
          //advisor_id: this.filters[2].model,
          type_tab: this.typeTab,
          status: this.typeTab,
          idProgram: this.moduleId == 9 ?  this.filters[2].model : this.moduleId,
          idUser: this.hasPermission ? null : this.currentUser.user_id
        };
        const { status, data } = await SendingReportsService.getSendingReports(
          params
        );

        if (status == 200) {
          this.items = data.data;
          this.startPage = data.from;
          this.totalRows = data.total;
          this.paginate.currentPage = data.current_page;
          this.paginate.perPage = data.per_page;
          this.endPage = data.last_page;
          this.toPage = data.to;
          return this.items || [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    ModalTracking(params) {
      this.statusModalTrackingShipment = true;
      this.lead_id = params.lead_id;
      this.account = params.account;
      this.client_name = params.client_name;
    },
  },
  
  watch: {
    getRefreshTable(value) {
        if(value) {
          this.reload();
          this.CHANGE_REFRESH_TABLE(false);
        } 
    },
  }
};
</script>


