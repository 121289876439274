const { amgApi } = require("@/service/axios");

class SendingReportsService {

  async getSendingReports(params) {
    try {
      const data = await amgApi.post(
        "/correspondence/get-sending-reports",
        params
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateStatusSendingReport(params){
    try {
      const data = await amgApi.post(
        "/correspondence/update-status-sending-reports",
        params
      );
      return data;
    } catch (error) {
      console.log(error);
    }

  }

  async getSendingReportsTracking(offerPaymentOrderId) {
    try {
      const data = await amgApi.get(
        `/correspondence/get-sending-reports-tracking/${offerPaymentOrderId}`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new SendingReportsService();
