import moment from "moment";
export default [
  {
    key: "client_name",
    label: "CLIENT",
    visible: true,
  },
  {
    key: "program",
    label: "PROGRAM",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "created_by",
    label: "REQUEST BY",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "files",
    label: "FILE",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    label: "TRACKING",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
   {
    key: "sent_date",
    label: "SENT DATE",
    thClass: "text-center",
    tdClass: "text-center",
    visible: false,
    formatter: (value) => {
      if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
      return "-";
    },
  }, 
  {
    key: "actions",
    label: "ACTIONS",
    thClass: "text-center",
    tdClass: "text-center",
    visible: false
  }
];
